<template>
  <v-container v-if="item">
    <OpenCardItem :item="item"></OpenCardItem>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OpenCardItem from "@/components/openItem/OpenCardItem";

export default {
  components: { OpenCardItem },
  mounted() {
    this.$store.commit("system/setBreadcrumbs", this.breadcrumbs);
  },
  watch: {
    "$route.params.item"() {
      this.$store.commit("system/setBreadcrumbs", this.breadcrumbs);
    },
    language() {
      this.$store.commit("system/setBreadcrumbs", this.breadcrumbs);
    }
  },
  computed: {
    ...mapGetters("business/", ["infos", "items", "categories"]),
    ...mapGetters("system/", ["language"]),
    ...mapGetters("loader/", ["loading"]),
    item() {
      return this.items.find(
        i =>
          this.objectContainValue(this.$route.params.item, i.title) ||
          i.uuid === this.$route.params.item
      );
    },
    category() {
      return this.categories.find(
        i =>
          this.objectContainValue(this.$route.params.category, i.name) ||
          i.uuid === this.$route.params.category
      );
    },
    breadcrumbs() {
      return [
        {
          icon: "fas fa-home",
          to: "/"
        },
        {
          text: this.$t("application.pages.menu.title"),
          to: "/menu",
          exact: true
        },
        {
          text: this.category.name[this.language.lcid],
          to: `/menu/${this.category.name[this.language.lcid]}`,
          exact: true
        },
        {
          text: this.item.title[this.language.lcid]
        }
      ];
    }
  },
  methods: {
    objectContainValue(value, object) {
      let found = false;
      for (let prop in object) {
        if (object[prop].trim() === value.trim()) {
          found = true;
        }
      }
      return found;
    }
  }
};
</script>

<style lang="scss" scoped>
.white {
  background-color: white;
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
}

h1 {
  color: grey;
  text-transform: capitalize;
  font-size: 2rem;
}
</style>
