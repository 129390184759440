<template>
  <div>
    <v-row justify="center" align="center">
      <v-col cols="12" class="d-flex justify-center image-wrapper">
        <div
          :style="{
            backgroundImage: 'url(' + getImage() + ')',
          }"
          class="image-background"></div>
        <div>
          <v-img
            class="item-image"
            eager
            contain
            max-height="100%"
            max-width="100%"
            :alt="item.title[language.lcid]"
            :src="getImage()"></v-img>
        </div>
      </v-col>
    </v-row>
    <div class="info-text elevation-5">
      <v-row justify="space-around">
        <v-col cols="auto">
          <span class="text-h4 title text-capitalize text--center">{{
            item.title[language.lcid]
          }}</span>
        </v-col>
        <v-col cols="auto">
          <span
            v-if="business.showPrices"
            class="text-h4 text--darken-1 text-capitalize text--center"
            >{{ $n(item.price, "currency") }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <hr class="title" />
        </v-col>

        <v-col
          class="horizontal-border-fade-left d-flex flex-column align-center">
          <span class="primary--text text-uppercase font-weight-bold">{{
            $t("application.editableitem.category")
          }}</span>
          <span
            class="text-capitalize font-weight-bold primary--text text--darken-2"
            >{{ categoryName }}</span
          >
        </v-col>
        <v-col
          class="horizontal-border-fade-right d-flex flex-column align-center">
          <div>
            <span class="primary--text text-uppercase font-weight-bold">{{
              $t("application.editableitem.status")
            }}</span>
          </div>
          <div class="primary--text text--darken-2">
            <span v-if="item.available" class="text-capitalize">{{
              $t("application.editableitem.available")
            }}</span>
            <span v-else class="text-capitalize">{{
              $t("application.editableitem.notAvailable")
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    this.categoryUUID = this.item.category;
  },
  data() {
    return {
      data: "",
      categoryUUID: null,
      isEditor: true,
      selected: [],
      selectedCategories: [],
    };
  },
  props: {
    item: Object,
  },
  computed: {
    ...mapGetters("business", ["infos", "categories"]),
    ...mapGetters("system", ["language"]),
    business() {
      return this.infos;
    },
    categoryName() {
      if (this.item.category) {
        return this.categories.find((f) => f.uuid === this.item.category).name[
          this.language.lcid
        ];
      }
      return "";
    },
  },
  methods: {
    closeDialog() {
      this.deleteQuestion = false;
      this.$emit("closeDialog");
    },
    getImage() {
      if (this.item.imageUrl) {
        return this.item.imageUrl;
      } else {
        return "/add.jpg";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info-text {
  position: relative;
  border-radius: 10px;
  background: white;
  position: relative;
  margin-top: 5rem;
  padding: 5rem 1rem;

  &::before {
    content: "";
    position: absolute;
    background-color: white;
    border-radius: 50% 50% 100% 0% / 100% 100% 0% 0%;
    top: -1rem;
    left: 2rem;
    width: 4rem;
    height: 4rem;
  }
}
.image-wrapper {
  position: relative;
  max-height: 50vh;
}
.image-background {
  position: absolute;
  width: 150%;
  height: 150%;
  background-size: cover;
  transform: translateY(-100px);
  filter: blur(20px);
}
.rounded-card {
  border-radius: 7px !important;
  overflow: hidden;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
}

.item-image {
  border-radius: 20px;
  overflow: hidden;
}
span {
  .title {
    font-weight: bold;
  }
  color: rgb(85, 85, 85);
}
</style>
